import { Injectable } from '@angular/core';
import {CookieService} from "../shared/services/cookie.service";
import {FunctionsService} from "../shared/services/functions.service";
import {OrdersService} from "../orders/orders.service";
import {UserService} from "../shared/services/user.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class OauthService {

  formLogin = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  url: string;

  constructor(
    private cookieService: CookieService,
    private functions: FunctionsService,
    private userService: UserService,
    private orderService: OrdersService,
    private http: HttpClient
  )
  {
    this.userService.user = this.getUser()
    this.url = environment.apiUrl;
  }

  login(user: any): Observable<any>
  {
    return this.http.post<any>(`${this.url}/order-app/auth/login`, user);
  }

  getUser(): any
  {
    if (this.cookieService.getCookie('currentUser'))
    {
      return JSON.parse(<string>this.cookieService.getCookie('currentUser'));
    }
  }

  logout(): void
  {
    this.cookieService.deleteCookie('currentUser');
    this.cookieService.deleteCookie('merchantInfo');
    this.cookieService.deleteCookie('i9Config');
    this.cookieService.deleteCookie('ifoodConfig');
    this.cookieService.deleteCookie('deliveryman');
    this.cookieService.deleteCookie('deliverymanIsActive');
    this.orderService.destroyInterval();
    this.functions.redirect('');
  }

  isLogin(): boolean
  {
    const user = this.cookieService.getCookie('currentUser');
    return !!user;
  }
}
